import { Carousel, Typography } from "@material-tailwind/react";

export function CarouselWithContent() {
    return (
        <Carousel className="min-h-screen relative	">
            <div className="relative ">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/background.jpg`}
                    alt="image 1"
                    className=" h-screen	 w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full	  place-items-center
                {/*bg-black/75*/}
                ">
                    <div className="w-3/4  absolute bottom-20  text-center md:w-2/4">

                        {/*<h1 className="mb-4 text-3xl md:text-4xl lg:text-5xl permanent-marker-regular">*/}
                        {/*    COMEBACK80*/}
                        {/*</h1>*/}

                    </div>
                </div>
            </div>
            <div className="relative ">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/background_2.jpg`}
                    alt="image 1"
                    className=" h-screen	 w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full	  place-items-center
                {/*bg-black/75*/}
                ">
                    <div className="w-3/4  absolute bottom-20  text-center md:w-2/4">

                        {/*<h1 className="mb-4 text-3xl md:text-4xl lg:text-5xl permanent-marker-regular">*/}
                        {/*    COMEBACK80*/}
                        {/*</h1>*/}

                    </div>
                </div>
            </div>
            <div className="relative ">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/background_3.jpg`}
                    alt="image 1"
                    className=" h-screen	 w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full	  place-items-center
                {/*bg-black/75*/}
                ">
                    <div className="w-3/4  absolute bottom-20  text-center md:w-2/4">

                        {/*<h1 className="mb-4 text-3xl md:text-4xl lg:text-5xl permanent-marker-regular">*/}
                        {/*    COMEBACK80*/}
                        {/*</h1>*/}

                    </div>
                </div>
            </div>
            <div className="relative ">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/background_4.jpg`}
                    alt="image 1"
                    className=" h-screen	 w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full	  place-items-center
                {/*bg-black/75*/}
                ">
                    <div className="w-3/4  absolute bottom-20  text-center md:w-2/4">

                        {/*<h1 className="mb-4 text-3xl md:text-4xl lg:text-5xl permanent-marker-regular">*/}
                        {/*    COMEBACK80*/}
                        {/*</h1>*/}

                    </div>
                </div>
            </div>


        </Carousel>
    );
}