import {Typography} from "@material-tailwind/react";

export function SimpleFooter() {
    return (
        <footer
            className="flex w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-white-50 py-6  px-6 text-center md:justify-between">
            <Typography color="white" className="font-normal">
                &copy; 2024 comeback80
            </Typography>
            <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
                <li>
                    <Typography
                        as="a"
                        href="#hero"
                        color="white"
                        className="font-normal transition-colors hover:text-red-700 focus:text-red-700"
                    >
                        Accueil
                    </Typography>
                </li>
                <li>
                    <Typography
                        as="a"
                        href="#bio"
                        color="white"
                        className="font-normal transition-colors hover:text-red-700 focus:text-red-700"
                    >
                        Bio
                    </Typography>
                </li>
                <li>
                    <Typography
                        as="a"
                        href="#gallery"
                        color="white"
                        className="font-normal transition-colors hover:text-red-700 focus:text-red-700"
                    >
                        Gallerie
                    </Typography>
                </li>
                <li>
                    <Typography
                        as="a"
                        href="#contact"
                        color="white"
                        className="font-normal transition-colors hover:text-red-700 focus:text-red-700"
                    >
                        Contact
                    </Typography>
                </li>
            </ul>
        </footer>
    );
}