import {CarouselWithContent} from "./Carousel";
import Hero from "./Hero";
import {TimelineWithIcon} from "./Timeline";
import Gallery from "./Gallery";
import Contact from "./Contact";

const Home = () => {
    return (
        <div>
            <CarouselWithContent/>
            <Hero/>
            <TimelineWithIcon/>
            <Gallery/>
            <Contact/>
        </div>
    )
}
export default Home;