import {
    Timeline,
    TimelineItem,
    TimelineConnector,
    TimelineHeader,
    TimelineIcon,
    TimelineBody,
    Typography,
} from "@material-tailwind/react";
import {BellIcon, CurrencyDollarIcon} from "@heroicons/react/24/solid";

export function TimelineWithIcon() {
    return (
        <div className="flex justify-center" id="bio">
            <section className="container">
                <div className="pb-8 px-4 mx-auto max-w-screen-xl text-center lg:pb-16 lg:pb-12 pt-4 lg:pt-8 lg:pt-6">
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-slate-100	 md:text-5xl lg:text-6xl dark:text-white">
                        Bio </h1>
                </div>
                <Timeline className="pr-4">
                    <TimelineItem>
                        <TimelineConnector/>
                        <TimelineHeader>
                            <TimelineIcon className="p-2">
                                <BellIcon className="h-4 w-4"/>
                            </TimelineIcon>
                            <Typography variant="h5" color="white">
                                2025
                            </Typography>
                        </TimelineHeader>
                        <TimelineBody className="pb-8">
                            <Typography color="white" className="font-normal text-white">
                                Comeback80 Tournée des 20 ans « Une Autre Histoire »
                            </Typography>
                        </TimelineBody>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineConnector/>
                        <TimelineHeader>
                            <TimelineIcon className="p-2">
                                <BellIcon className="h-4 w-4"/>
                            </TimelineIcon>
                            <Typography variant="h5" color="white">
                                2020
                            </Typography>
                        </TimelineHeader>
                        <TimelineBody className="pb-8">
                            <Typography color="white" className="font-normal text-white">
                                Ils participent à une adaptation du titre « Une autre histoire » de
                                Gérard Blanc sur le dernier album de 23 titres dédié à la mémoire de la carrière de
                                l’artiste « Pour la Faire rêver » produit par Brigitte Blanc (Blanc Musiques ) distribué
                                par Wagram Music ainsi que le clip diffusé sur ( TF1, C star, MCM, M6 music, W9 )
                                il participe également aux arrangement et l'enregistrement du titre« Toutes les
                                aventures s'achèvent à Ibiza » Vmusic Production distribution UNIVERSAL, de
                                Jean Quentin LAROCHE VALMONT connu pour son titre " TAS LE LOOK COCO".
                            </Typography>
                        </TimelineBody>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineConnector/>
                        <TimelineHeader>
                            <TimelineIcon className="p-2">
                                <BellIcon className="h-4 w-4"/>
                            </TimelineIcon>
                            <Typography variant="h5" color="white">
                                2010
                            </Typography>
                        </TimelineHeader>
                        <TimelineBody className="pb-8">
                            <Typography color="white" className="font-normal text-white">
                                Leur spectacle dynamique et interactif avec le public au rayonnement national
                                ,les amènent après + 500 concerts en LIVE à être invités par Patrick Sébastien à
                                son émission TV Les Années Bonheur.
                            </Typography>
                        </TimelineBody>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineConnector/>
                        <TimelineHeader>
                            <TimelineIcon className="p-2">
                                <BellIcon className="h-4 w-4"/>
                            </TimelineIcon>
                            <Typography variant="h5" color="white">
                                2005
                            </Typography>
                        </TimelineHeader>
                        <TimelineBody className="pb-8">
                            <Typography color="white" className="font-normal text-white">
                                Il créé son propre groupe COMEBACK80 avec Frédéric Aberson
                                ( chant lead claviers ) Alain Carminati ( Choeurs-Guitare ) et reprennent en Live
                                tous les plus grands Tubes des Stars années 80 qu'ils accompagnent depuis 30
                                ans . ( Thierry Pastor,Jean Pierre Mader, Phil Barney , LIO,Laroche Valmont....)
                            </Typography>
                        </TimelineBody>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineConnector/>
                        <TimelineHeader>
                            <TimelineIcon className="p-2">
                                <BellIcon className="h-4 w-4"/>
                            </TimelineIcon>
                            <Typography variant="h5" color="white">
                                1998
                            </Typography>
                        </TimelineHeader>
                        <TimelineBody className="pb-8">
                            <Typography color="white" className="font-normal text-white">
                                Il participe à l'enregistrement TV du titre Plus près des Etoiles sur l'émission
                                " Tapis Rouge présenté par Michel Drucker invités Jean Jacques Goldman &
                                Céline Dion ( 5 Millions de téléspectateurs ).
                            </Typography>
                        </TimelineBody>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineConnector/>
                        <TimelineHeader>
                            <TimelineIcon className="p-2">
                                <BellIcon className="h-4 w-4"/>
                            </TimelineIcon>
                            <Typography variant="h5" color="white">
                                1992-1999 </Typography>
                        </TimelineHeader>
                        <TimelineBody className="pb-8">
                            <Typography color="white" className="font-normal text-white">
                                Fred accompagne
                                <a href="https://fr.wikipedia.org/wiki/%C3%89mile_Wandelmer#Le_parcours_solo"
                                   target="_blank" className="text-slate-200  font-bold"> Émile Wandelmer </a>
                                chanteur du Groupe GOLD à la
                                batterie sur les plus grandes scènes musicales ( France - Europe )
                            </Typography>
                        </TimelineBody>
                    </TimelineItem>
                </Timeline>
            </section>
        </div>
    );
}