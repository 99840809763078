import './App.css';
import Home from "./components/Home";
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import {NavbarSimple} from "./components/SimpleNavbar";
import {SimpleFooter} from "./components/Footer";

function App() {
    return (
        <div>
            <Router>
                <NavbarSimple/>
                <div className="flex-grow overflow-x-hidden	">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                </div>
                <SimpleFooter/>
            </Router>
            <script src="https://cdn.jsdelivr.net/npm/flowbite@2.4.1/dist/flowbite.min.js"></script>

        </div>
    )
}

export default App;
