export function Example() {
    return (
        <div className="w-full h-full ">
            <iframe
                className="w-full aspect-video	"
                src="https://www.youtube.com/embed/d8bOf2Pn0d4?si=Lm8IJZnd-V8ojZOG"
                title="YouTube video player" frameBorder="0"
                allow=" autoplay"
                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>

    );
}