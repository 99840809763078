import GroupCard from "./GroupCard";
import {Component as Modal} from "./Modal";

const Hero = () => {
    return (
        <div className="flex justify-center"
             id="hero">
            <section className="container ">
                <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:py-12">
                    <h1 className="mb-8 text-4xl font-extrabold tracking-tight leading-none text-slate-100	 md:text-5xl lg:text-6xl dark:text-white">
                        COMEBACK80</h1>
                    <p className="mb-8 text-xl font-normal text-slate-100	 lg:text-2xl sm:px-16 xl:px-48 	">
                        « Une Autre Histoire »</p>

                    <p className=" text-lg font-normal text-slate-100	 lg:text-xl sm:px-16 xl:px-48 	">
                        2025 - Tournée des 20 ans</p>
                </div>

                <div
                    className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">

                    <a href={`${process.env.PUBLIC_URL}/assets/biographie_comeback80_2025.pdf`}
                       download="biographie_comeback80_2025.pdf"
                       className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white  bg-red-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"/>
                        </svg>
                        <span className="ml-2 flex items-center">
                                Bio
                            </span>

                    </a>
                    <Modal/>
                </div>
                <GroupCard/>

            </section>
        </div>
    )
}

export default Hero