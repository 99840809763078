const Gallery = () => {

    return (
        <section id="gallery">
            <div className="flex justify-center py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                <h1
                    className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-slate-100	 md:text-5xl lg:text-6xl dark:text-white"
                >Gallerie </h1>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4"
                 id="gallery"
            >


                <div className="grid gap-4">
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/fred_k.jpg`}
                             alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/fred_a_1.jpg`}
                             alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/alain_2.jpg`}
                             alt=""/>
                    </div>
                </div>
                <div className="grid gap-4">
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/alain_3.jpg`}
                             alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/fred_k_4.jpg`}
                             alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/fred_a_5.jpg`}
                             alt=""/>
                    </div>
                </div>
                <div className="grid gap-4">
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/fred_a_6.jpg`}
                             alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/groupe_7.jpg`}
                             alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/fred_k_8.jpg`}
                             alt=""/>
                    </div>
                </div>
                <div className="grid gap-4">
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/groupe_9.jpg`}
                             alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/alain_10.jpg`}
                             alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full  "
                             src={`${process.env.PUBLIC_URL}/assets/gallery/groupe_11.jpg`}
                             alt=""/>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Gallery;