import React from "react";
import {CardDefault} from "./Card";

const GroupCard = () => {
    return (
        <div className="px-4 pt-4 mx-auto text-center justify-center">
            <span className="font-semibold text-gray-400 uppercase text-2xl lg:text-3xl ">MEMBRES</span>
            <div className="flex flex-wrap justify-center items-center  text-gray-500 mt-8  ">
                <div className="m-8">
                    <CardDefault imageSrc={`${process.env.PUBLIC_URL}/assets/fred_a.jpg`} name={"Fred"} />                </div>
                <div className="m-8">
                    <CardDefault imageSrc={`${process.env.PUBLIC_URL}/assets/fred_k.jpg`}  name={"Fred"}/>                </div>

                <div className="m-8">
                    <CardDefault imageSrc={`${process.env.PUBLIC_URL}/assets/alain_1.jpg`}  name={"Alain"}/>                </div>
            </div>
        </div>
    )
}

export default GroupCard;