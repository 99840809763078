const Contact = () => {
    return (
        <div className="flex justify-center"
        id="contact">
            <section className="container">
                <div className="pt-8 px-4 mx-auto max-w-screen-xl text-center lg:pt-16 ">
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-slate-100	 md:text-5xl lg:text-6xl dark:text-white">
                        Contact - Devis</h1>
                </div>
                <div className="container px-6 pb-12 mx-auto">


                    <div className="grid grid-cols-1 gap-12 mt-10 md:grid-cols-2 ">
                        <a href="mailto:amcstudio@orange.fr" target="_blank"  rel="noreferrer">
                        <div className="flex flex-col items-center justify-center text-center lg:items-end">
                <span className="p-3    text-white rounded-full bg-transparent">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"/>
                    </svg>
                </span>

                            <h2 className="mt-4 text-lg font-medium text-slate-50	  ">Email</h2>
                            <p className="mt-2 text-slate-100	   ">Amc Management</p>
                            <p className="mt-2    text-white    ">amcstudio@orange.fr</p>
                        </div>
                        </a>

                        <a href="tel:0603251339" target="_blank"  rel="noreferrer">
                        <div className="flex flex-col items-center justify-center text-center lg:items-start">
                <span className="p-3    text-white rounded-fullbg-transparent">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                         stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"/>
                    </svg>
                </span>

                            <h2 className="mt-4 text-lg font-medium text-slate-50	  ">Phone</h2>
                            <p className="mt-2 text-slate-100	   ">Amc Management</p>
                            <p className="mt-2    text-white    ">06 03 25 13 39</p>
                        </div>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Contact;