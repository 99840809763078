import {
    Card,
    CardHeader,
    CardBody,
    Typography,
} from "@material-tailwind/react";
export function CardDefault({ imageSrc, name}) {
    return (
        <Card
            className="mt-6 w-80 rounded-none "
            color="transparent"
        >
            <CardHeader color="blue-gray" className="relative object-cover aspect-square rounded-none">
                <img
                    src={imageSrc}
                    alt="card-image"
                    className=" h-full object-cover aspect-square "
                />
            </CardHeader>
            <CardBody>
                <Typography variant="h5" color="white" className="mb-2">
                    {name}
                </Typography>
            </CardBody>
        </Card>
    );
}